import { API_PATH } from './constants'

const createCustomer = async (customerData, eventParams) => {
  let response
  let json

  const customerDataMap = {
    ...customerData,
  }

  // if (eventParams && eventParams.country) {
  //   customerDataMap.country = eventParams.country
  // }

  try {
    response = await fetch(`${API_PATH}/customer-create`, {
      method: 'POST',
      body: JSON.stringify(customerDataMap),
    })

    json = await response.json()
  } catch (error) {
    throw error
  }

  if (response.status === 200) {
    return json
  }

  throw new Error(json.message)
}

export default createCustomer
