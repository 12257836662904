import React, { useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import i18n from '../i18n/initI18n'
import RichTextRenderer from '../components/RichTextRender'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import AvailableLoansBanner from '../components/AvailableLoansBanner'
import MainPageSteps from '../components/MainPageSteps'

export const mainPageQuery = graphql`
  query {
    contentfulMainPage(node_locale: { eq: "fi-FI" }) {
      banner {
        text {
          raw
        }
        background {
          file {
            url
          }
        }
      }
      richContent {
        raw
      }
      disclaimer {
        raw
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(mainPageQuery)
  const { banner, richContent, disclaimer } = data.contentfulMainPage

  // useEffect(() => {
  //   if(i18n.language) {
  //     navigate(`/${i18n.language}`)
  //   } else {
  //     navigate('/fi')
  //   }
  //   }, [])

  return (
    // null
    <Layout languageRedirect={true}>
      <SEO
        title="Kyrö Distillery Tynnyrilaina"
        image={`https:${banner.background.file.url}`}
      />
      <div
        className="homepage-banner"
        style={{ backgroundImage: `url(https:${banner.background.file.url})` }}
      >
        <RichTextRenderer node={banner.text.raw} />
      </div>
      <div className="home-page-content-container">
        <div className="flex-column">
          <div className="flex-row">
            <div className="home-page-content flex-column">
              <RichTextRenderer node={richContent.raw} />
            </div>
            <AvailableLoansBanner />
          </div>
        </div>
      </div>
      <MainPageSteps />
      <div className="home-page-content-container">
        <div className="home-page-disclaimer">
          <RichTextRenderer node={disclaimer.raw} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
