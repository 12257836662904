import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../context/context'
import Spinner from './Spinner'
import seal from '../static/images/kdc-seal.png'
import createCustomer from '../utils/klaviyo'

const AvailableLoansBanner = () => {
  const { loanData } = useContext(StateContext)
  const { t } = useTranslation()
  const [maillistStatus, setMaillistStatus] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
  })

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = async e => {
    e.preventDefault()
    let data = await createCustomer({
      email: formData.email,
      can_email: true,
      mailing_lists: ['loan-waiters'],
    })
    setMaillistStatus(true)
  }

  return (
    <div className="home-page-available-loans-banner">
      <h3>{t('AVAILABLE_COLLATERAL')}</h3>
      {loanData && loanData.availableLoansCount !== null ? (
        parseInt(loanData.availableLoansCount) > 0 ? (
          <>
            <h2>{`${loanData?.availableLoansCount}/${loanData?.totalLoansCount}`}</h2>
            <img className="home-page-seal" src={seal}></img>
            <h2>{t('HOMEPAGE_REGISTER_TITLE')}</h2>
            <Link className="btn-primary link-button" to="/app/register">
              {t('REGISTER')}
            </Link>
            <h2>{t('HOMEPAGE_LOGIN_TITLE')}</h2>
            <Link className="btn-secondary link-button" to="/app/login">
              {t('LOGIN')}
            </Link>
          </>
        ) : (
          <>
            <h2>{`${loanData?.availableLoansCount}/${loanData?.totalLoansCount}`}</h2>
            <p>{t('NO_AVAILABLE_LOANS_INFO')}</p>
            {maillistStatus === false && (
              <form className="form-container">
                <label htmlFor="email">{t('EMAIL')}</label>
                <input type="email" name="email" onChange={e => onChange(e)} />
                <button className="btn-primary" onClick={onSubmit}>
                  {t('WAITLIST_BUTTON')}
                </button>
              </form>
            )}
            {maillistStatus === true && (
              <>
                <div className="m-2" />
                <h4 className="brown">{t('WAITLIST_STATUS')}</h4>
                <div className="m-2" />
              </>
            )}
            <h3>{t('NO_AVAILABLE_LOANS_LOGIN_TITLE')}</h3>
            <Link className="btn-secondary link-button" to="/app/login">
              {t('LOGIN')}
            </Link>
            <Link className="btn-no-outline link-button" to="/app/register">
              {t('REGISTER')}
            </Link>
          </>
        )
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default AvailableLoansBanner
