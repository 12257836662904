import React from 'react'
import { useTranslation } from 'react-i18next'
import stepsId from '../static/images/kdc-steps-id.png'
import stepsFiles from '../static/images/kdc-steps-files.png'
import stepsContract from '../static/images/kdc-steps-contract.png'
import stepsWeb from '../static/images/kdc-steps-web.png'
import stepsBarrel from '../static/images/kdc-steps-barrel.png'

const MainPageSteps = () => {
  const { t } = useTranslation()
  return (
    <div className="main-page-steps-container centered-text">
      <div className="main-page-steps-inner">
        <div className="main-page-steps-item">
          <img className="seal" src={stepsId} />
          <h3 className="brown bold-text">1.</h3>
          <p>{t('MAIN_STEPS_1')}</p>
        </div>
        <div className="main-page-steps-item">
          <img className="seal" src={stepsFiles} />
          <h3 className="brown bold-text">2.</h3>
          <p>{t('MAIN_STEPS_2')}</p>
        </div>
        <div className="main-page-steps-item">
          <img className="seal" src={stepsWeb} />
          <h3 className="brown bold-text">3.</h3>
          <p>{t('MAIN_STEPS_3')}</p>
        </div>
        <div className="main-page-steps-item">
          <img className="seal" src={stepsContract} />
          <h3 className="brown bold-text">4.</h3>
          <p>{t('MAIN_STEPS_4')}</p>
        </div>
        <div className="main-page-steps-item">
          <img className="seal" src={stepsBarrel} />
          <h3 className="brown bold-text">5.</h3>
          <p>{t('MAIN_STEPS_5')}</p>
        </div>
      </div>
    </div>
  )
}

export default MainPageSteps
